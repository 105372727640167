import Modal from './modal.js';
import EditorJS from '@editorjs/editorjs';
import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import List from '@editorjs/list';
import Warning from '@editorjs/warning';
import CodeTool from '@editorjs/code';
import LinkTool from '@editorjs/link';
import ImageTool from '@editorjs/image';
import RawTool from '@editorjs/raw';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import Checklist from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
import SimpleImage from '@editorjs/simple-image';

export default class News {

    newsModal = null;
    editorContent = null;

    constructor(modalId, locale) {
        this.locale = locale;
        this.newsModal = new Modal(modalId, this.locale);
        this.editorContent = this.newsModal.modal.querySelector('.modal-body');
        this.initEditor();
        this.prepareNewsButtons();
    }

    initEditor = () => {
        this.editor = new EditorJS({
            holder: this.editorContent,
            minHeight: 0,
            readOnly: true,
            logLevel: 'ERROR',
            tools: {
                embed: {
                    class: Embed,
                    inlineToolbar: true,
                    config: {
                        services: {
                            youtube: true,
                            coub: true,
                            codepen: true,
                            imgur: true,
                            gfycat: true,
                            'twitch-video': true,
                            'twitch-channel': true,
                            vimeo: true,
                            instagram: true,
                            twitter: true,
                        },
                    },
                },
                table: {
                    class: Table,
                    inlineToolbar: true,
                    config: {
                        rows: 2,
                        cols: 3,
                    },
                },
                marker: {
                    class: Marker,
                    shortcut: 'CMD+SHIFT+M',
                },
                list: {
                    class: List,
                    inlineToolbar: true,
                },
                warning: {
                    class: Warning,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+W',
                },
                code: CodeTool,
                linkTool: {
                    class: LinkTool,
                },
                image: {
                    class: ImageTool,
                },
                raw: RawTool,
                header: {
                    class: Header,
                    shortcut: 'CMD+SHIFT+H',
                },
                quote: {
                    class: Quote,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+O',
                },
                checklist: {
                    class: Checklist,
                    inlineToolbar: true,
                },
                delimiter: Delimiter,
                inlineCode: {
                    class: InlineCode,
                },
                simpleImage: SimpleImage,
            },
        });
    }

    prepareNewsButtons = () => {
        const buttons = document.querySelectorAll('.news__item > .button');

        buttons.forEach(button => {
            button.addEventListener('click', async () => {
                const slug = button.dataset.slug;
                const data = await this.getNews(slug);
                this.editor.render(data.data);
                this.newsModal.setTitle(data.title);
                this.newsModal.setHeaderColor(data.color);
                this.newsModal.open();
            });
        });
    }

    getNews = (slug) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch(`./NewsManagement/get/${slug}`, {
                    method: "POST",
                });
                const notification = await response.json();

                if (notification.errors.length === 0) {
                    resolve(notification.data.news);
                }
                reject(notification.errors);
            } catch (error) {
                reject(error);
            }
        });
    }
}