import intlTelInput from 'intl-tel-input';
import UtilsITI from 'intl-tel-input/build/js/utils';
import 'daterangepicker';

export default class Form {

    phoneInput = null;
    dateInput = null;
    formSection = null;
    formContainer = null;
    form = null;

    constructor(locale, notification) {
        try {
            this.locale = locale;
            this.notification = notification;
            this.formSection = document.getElementById('formSection');
            this.formContainer = this.formSection.querySelector('.form__container');
            this.form = this.formSection.querySelector('form');
            this.preparePhoneInput();
            this.prepareDateInput();
            this.bindSubmitForm();
            this.createLoader();
        } catch (error) {
            this.notification.showDefaultError(error);
        }
    }

    preparePhoneInput = () => {
        const input = document.getElementById("phoneNumber");

        this.phoneInput = intlTelInput(input, {
            separateDialCode: true,
            initialCountry: 'PL',
            preferredCountries: ['PL'],
            utilsScript: UtilsITI,
        });
    }

    prepareDateInput = () => {
        const input = document.getElementById('birthDay');

        $(input).daterangepicker({
            autoUpdateInput: false,
            singleDatePicker: true,
            applyButtonClasses: "button",
            cancelClass: "button button--alt",
            locale: {
                format: 'YYYY-MM-DD',
                separator: " - ",
                applyLabel: this.locale.drpLabelApply,
                cancelLabel: this.locale.drpLabelCancel,
                fromLabel: this.locale.drpLabelFrom,
                toLabel: this.locale.drpLabelTo,
                daysOfWeek: [
                    this.locale.sunday,
                    this.locale.monday,
                    this.locale.tuesday,
                    this.locale.wednesday,
                    this.locale.thursday,
                    this.locale.friday,
                    this.locale.saturday,
                ],
                monthNames: [
                    this.locale.january,
                    this.locale.february,
                    this.locale.march,
                    this.locale.april,
                    this.locale.may,
                    this.locale.june,
                    this.locale.july,
                    this.locale.august,
                    this.locale.september,
                    this.locale.october,
                    this.locale.november,
                    this.locale.december,
                ],
                firstDay: 1
            }
        });

        $(input).on('apply.daterangepicker', function (ev, picker) {
            $(this).val(picker.startDate.format('YYYY-MM-DD'));
        });

        $(input).on('cancel.daterangepicker', function (ev, picker) {
            $(this).val('');
        });
    }

    bindSubmitForm = () => {
        this.form.addEventListener('submit', (e) => {
            try {
                e.preventDefault();
                this.turnOnLoader(this.locale.loaderSaving);
                grecaptcha.ready(() => {
                    grecaptcha.execute(process.env.RECAPTCHA_PUBLIC_KEY, {
                        action: 'submit'
                    }).then(async (token) => {
                        await this.createCustomer(token);
                        this.turnOffLoader();
                    });
                });
            } catch (error) {
                this.notification.showDefaultError(error);
                this.turnOffLoader();
            }
        });
    }

    createCustomer = async (token) => {
        return new Promise(async (resolve, reject) => {
            try {
                const formData = new FormData(this.form);
                formData.append('token', token);
                this.modifyFormData(formData);
                const response = await fetch('./CustomerManagement/create', {
                    method: "POST",
                    body: formData,
                });
    
                const notification = await response.json();
                this.notification.formatFormResponse(notification);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
        
    }

    modifyFormData = (formData) => {
        formData.append('ref_shop', this.form.querySelector('#shop').value);
        formData.append('emailAgreement', this.form.querySelector('#emailAgreement').checked);
        formData.append('smsAgreement', this.form.querySelector('#smsAgreement').checked);
    }

    createLoader = () => {
        const loader = document.createElement('div');
        loader.classList.add("form-loader", "off");

        const spinner = document.createElement('div');
        spinner.classList.add("spinner-border");
        spinner.role = "status";
        loader.appendChild(spinner);

        const p = document.createElement('p');
        p.innerHTML = this.locale.loaderDefault;
        loader.appendChild(p);

        this.formContainer.appendChild(loader);
    }

    turnOnLoader = (message = null) => {
        const loader = this.formContainer.querySelector('.form-loader')
        loader.classList.remove('off');
        loader.style.height = `${this.form.offsetHeight}px`;
        this.form.style.display = "none";

        if (message !== null) {
            this.formContainer.querySelector('.form-loader p').innerHTML = message;
        }
    }

    turnOffLoader = () => {
        this.form.style.display = "grid";
        this.formContainer.querySelector('.form-loader').classList.add('off');
    }
}
