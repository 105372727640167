import Notification from './notification.js';
require('regenerator-runtime');
require('jquery');
require('popper.js');
require('bootstrap');

export default class Global {

    constructor() {
        this.setLocale();
        this.notification = new Notification(this.locale);
    }

    setLocale = () => {
        if (langObject) {
            this.locale = langObject
        }
    }

    formatFormResponse = response => {
        if (response.url !== null) {
            window.location.href = response.url;
        }

        if (response.errors.length > 0) {
            response.errors.forEach(error => {
                this.notification.show(this.notification.types.danger, error);
            });
        }

        if (response.success !== null) {
            this.notification.show(this.notification.types.success, response.success);
        }
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}