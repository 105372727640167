export default class Modal {

    form = null;

    constructor(modalId, locale) {
        this.locale = locale;
        this.modal = document.getElementById(modalId);
        this.id = modalId;
        this.submitButton = this.modal.querySelector('.modal-footer button:first-child');
        this.formElement = this.modal.querySelector('form');
        this.createLoader();
    }

    setForm = form => {
        this.form = form;
    }

    open = () => {
        $(this.modal).modal(`show`);
    }

    close = (clearData = false) => {
        if (clearData && this.form !== null) {
            this.form.clear();
        }

        $(this.modal).modal(`hide`);
    }

    setTitle = (title) => {
        const titleContainer = this.modal.querySelector('.modal-title');

        titleContainer.innerText = title;
    }

    setHeaderColor = (rgbColor) => {
        const header = this.modal.querySelector('.modal-header');

        header.style.backgroundColor = rgbColor;
        this.setHeaderTextColor(rgbColor);
    }

    setHeaderTextColor = (backgroudRgbColor) => {
        const header = this.modal.querySelector('.modal-header');
        const textLight = '#f8f9fa';
        const textDark = '#343a40';
        const sanitizedColor = backgroudRgbColor.replace('#', '');
		const r = parseInt(sanitizedColor.substr(0, 2), 16);
		const g = parseInt(sanitizedColor.substr(2, 2), 16);
		const b = parseInt(sanitizedColor.substr(4, 2), 16);
		const colorContrast = (r * 299 + g * 587 + b * 114) / 1000;
        
		header.style.color = colorContrast >= 128 ? textDark : textLight;
    }

    turnOnLoader = (message = null) => {
        const { modal } = this;

        modal.querySelector('.modal-body').style.display = "none";
        modal.querySelector('.modal-footer').style.display = "none";
        modal.querySelector('.modal-loader').classList.remove('off');

        if (message !== null) {
            modal.querySelector('.modal-loader p').innerHTML = message;
        }
    }

    turnOffLoader = () => {
        const { modal } = this;

        modal.querySelector('.modal-body').style.display = "block";
        modal.querySelector('.modal-footer').style.display = "flex";
        modal.querySelector('.modal-loader').classList.add('off');
    }

    createLoader = () => {
        const modalContent = this.modal.querySelector('.modal-content');
        const loader = document.createElement('div');
        loader.classList.add("modal-loader", "off");

        const spinner = document.createElement('div');
        spinner.classList.add("spinner-border");
        spinner.role = "status";
        loader.appendChild(spinner);

        const p = document.createElement('p');
        p.innerHTML = this.locale.labelDefault;
        loader.appendChild(p);

        modalContent.appendChild(loader);
    }
}