import * as L from 'leaflet';

export default class Map {

    shops = [];
    mapContainer;
    leaflet = null;
    defaultLongitude = 51.919437;
    defaultLatitude = 19.145136;
    defaultZoom = 6;

    constructor(mapContainer) {
        try {
            this.mapContainer = mapContainer;
            this.initMap();
            this.addMarkers();
        } catch (error) {
            console.error(error);
        }
    }

    getShops = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch(`./ShopManagement/getAll`, {
                    method: "POST",
                });
                const notification = await response.json();

                if (notification.errors.length === 0) {
                    resolve(notification.data.list);
                }
                reject(notification.errors);
            } catch (error) {
                reject(error);
            }
        });
    }

    initMap = () => {
        this.mapContainer.style.height = '100%';
        this.leaflet = {
            map: L.map(this.mapContainer).setView([this.defaultLongitude, this.defaultLatitude], this.defaultZoom),
        };

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(this.leaflet.map);
    }

    addMarkers = async () => {
        const shops = await this.getShops();

        shops.forEach((shop) => {
            if (shop.longitude && shop.latitude) {
                const template = this.mapMarkerTemplate(shop);
                const mapIcon = L.icon({
                    iconUrl: `./assets/src/images/marker.svg`,
                    iconSize: [60, 40],
                    shadowUrl: `./assets/src/images/marker-shadow.png`,
                    shadowSize: [80, 80],
                    shadowAnchor: [24, 58],
                });
                const marker = L.marker([shop.longitude, shop.latitude], {
                    icon: mapIcon,
                }).addTo(this.leaflet.map);

                marker.bindPopup(template);
            }
        });
    }

    mapMarkerTemplate = (shop) => {
        // * TODO: kod tylko nieco poprawiony po partaczu - w wolnej chwili zrefactorować tu i w `\assets\src\sass\vendors_extensions\_leaflet.scss`
        const popup = document.createElement('div');
        popup.classList.add('leaflet-popup-content-box');

        const shopHeader = document.createElement('div');
        shopHeader.classList.add('leaflet-popup-content-box-header');

        const shopBadge = document.createElement('div');
        shopBadge.classList.add('badge', `badge--${shop.subtype}`);
        shopBadge.innerText = shop.subtype;
        shopHeader.appendChild(shopBadge);

        const shopName = document.createElement('div');
        shopName.innerText = shop.name;
        shopHeader.appendChild(shopName);
        popup.appendChild(shopHeader);

        const shopBody = document.createElement('div');
        shopBody.classList.add('leaflet-popup-content-box-body');

        const shopFirstLineAddress = document.createElement('div');
        shopFirstLineAddress.classList.add('leaflet-popup-content-box-body-first-line-address');

        const shopStreet = document.createElement('div');
        shopStreet.innerText = `${shop.street},`;
        shopFirstLineAddress.appendChild(shopStreet);
        shopBody.appendChild(shopFirstLineAddress);

        const shopSecondLineAddress = document.createElement('div');
        shopSecondLineAddress.classList.add('leaflet-popup-content-box-body-second-line-address');

        const shopZipCode = document.createElement('div');
        shopZipCode.innerText = shop.zip_code;
        shopSecondLineAddress.appendChild(shopZipCode);

        const shopCity = document.createElement('div');
        shopCity.innerText = shop.city;
        shopSecondLineAddress.appendChild(shopCity);
        shopBody.appendChild(shopSecondLineAddress);
        popup.appendChild(shopBody);

        if (shop.olx !== '') {
            const shopOlxBox = document.createElement('div');
            shopOlxBox.classList.add('leaflet-popup-content-box-body-olx');

            const shopOlx = document.createElement('a');
            shopOlx.href = shop.olx;
            shopOlx.target = '_blank';
            shopOlx.rel = 'noreferrer';
            shopOlx.innerHTML = '<i class="icon mdi mdi-web"></i> Sklep OLX';
            shopOlxBox.appendChild(shopOlx);
            popup.appendChild(shopOlxBox);
        }

        const shopEmailBox = document.createElement('div');
        shopEmailBox.classList.add('leaflet-popup-content-box-body-email');

        const shopEmail = document.createElement('a');
        shopEmail.href = `mailto:${shop.email}`;
        shopEmail.innerText = shop.email;
        shopEmailBox.appendChild(shopEmail);
        popup.appendChild(shopEmailBox);

        const shopPhoneBox = document.createElement('div');
        shopPhoneBox.classList.add('leaflet-popup-content-box-body-phone');

        const shopPhone = document.createElement('a');
        shopPhone.href = `tel:${shop.phone}`;
        shopPhone.innerText = shop.phone;
        shopPhoneBox.appendChild(shopPhone);
        popup.appendChild(shopPhoneBox);

        return popup;
    }
}
