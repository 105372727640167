import Modal from './modal.js';

export default class News {

    contactModal = null;

    constructor(modalId, locale) {
        try {
            this.locale = locale;
            this.contactModal = new Modal(modalId, this.locale);

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    init = () => {
        const sectionButton = document.querySelector('.whats-app-content p');
        const menuButton = document.querySelector('.social-media-icons li a:has(img.whatsapp)');

        [sectionButton, menuButton].forEach(button => {
            button.addEventListener('click', () => {
                this.contactModal.open();
            });
        });

        this.bindShopSelect();
    }

    bindShopSelect = () => {
        const select = document.getElementById('shopSelect');

        select.addEventListener('change', async () => {
            const modalBody = this.contactModal.modal.querySelector('.modal-body');
            const id = select.value;
            const shop = await this.getShop(id);
            const oldButton = this.contactModal.modal.querySelector('.whatsapp-button');
            oldButton && oldButton.remove();
            const whatsAppButton = this.createWhatsAppButton(shop.phone);

            modalBody.appendChild(whatsAppButton);
        });
    }

    createWhatsAppButton = (phoneNumber) => {
        const dialcode = 48;
        const button = document.createElement('a');
        button.classList.add('whatsapp-button');
        button.setAttribute('target', "_blank");
        button.href = `//api.whatsapp.com/send?phone=${dialcode}${phoneNumber}&text=${this.locale.labelWhatsAppText}`;
        button.innerHTML = `${this.locale.buttonCall} <img src="./assets/src/images/whatsapp-logo.png">`;
        button.addEventListener('click', () => {
            this.contactModal.close();
        });

        return button;
    }

    getShop = (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch(`./ShopManagement/get/${id}`, {
                    method: "POST",
                });
                const notification = await response.json();

                if (notification.errors.length === 0) {
                    resolve(notification.data.shop);
                }
                reject(notification.errors);
            } catch (error) {
                reject(error);
            }
        });
    }
}