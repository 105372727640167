import Global from './global/global.js';
import Modal from './global/modal.js';
import Map from './global/map.js';
import News from './global/news.js';
import Contact from './global/contact.js';
import Form from './global/form.js';
import Carousel from './global/carousel.js';
import SmoothScroll from 'smooth-scroll';
import AOS from 'aos';
import 'bootstrap-select';

class DashboardIndexView extends Global {

    constructor() {
        try {
            super();
            this.newspaperModal = new Modal('newspaperModal', this.locale);
            this.map = new Map(document.getElementById('map'));
            this.news = new News('newsModal', this.locale);
            this.contact = new Contact('whatsAppModal', this.locale);
            this.carousel = new Carousel();
            this.form = new Form(this.locale, this.notification);

            this.bindMobileMenuButton();
            this.bindNewspaperButton();
            this.initSelectPickers();
            this.initSmoothScroll();
            this.initAnimateOnScroll();
        } catch (error) {
            this.notification.showDefaultError(error);
        }
    }

    bindMobileMenuButton = () => {
        const navbar = document.getElementById('navbar');
        const navbarMobileButton = document.getElementById('toggleMobileNav');

        navbarMobileButton.addEventListener('click', () => {
            navbar.classList.toggle('navbar--open');
        });
    }

    initSelectPickers = () => {
        $.fn.selectpicker.Constructor.DEFAULTS.liveSearch = true;
        $.fn.selectpicker.Constructor.DEFAULTS.size = 8;
    }

    initSmoothScroll = () => {
        new SmoothScroll('a[href*="#"]', {
            speed: 500,
            offset: 50,
            speedAsDuration: true,
            easing: 'easeInOutCubic',
            updateURL: true,
            popstate: true,
        });
    }

    initAnimateOnScroll = () => {
        AOS.init({
            useClassNames: true,
            initClassName: false,
            animatedClassName: 'animated',
        });
    }

    bindNewspaperButton = () => {
        const newspaperButton = document.getElementById('newspaperButton');
        newspaperButton.addEventListener('click', () => {
            this.newspaperModal.open();
        });
    }
}

new DashboardIndexView();