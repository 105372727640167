import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

export default class Carousel {

    constructor() {
        try {
            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    init = () => {
        Swiper.use([Navigation, Pagination, Autoplay]);

        this.swiper = new Swiper('.swiper', {
            direction: 'horizontal',
            autoplay: {
                delay: 10000,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            watchSlidesVisibility: true,
            preloadImages: false,
            lazy: true,
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
        });
    }
}